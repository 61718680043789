
  
  /* Disable dragging of images */
  .image-bouncer {
    position: absolute;
    z-index: 1;
    transform: translateZ(0);
    pointer-events: none; /* Disable pointer events to prevent dragging */
 
  }
  
  .image-bouncer img {
    pointer-events: auto; /* Enable pointer events on the image itself */
    cursor: pointer;
  }
  
  /* Disable right-click context menu */
  .image-bouncer img {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-drag: none; /* Safari */
    -khtml-user-drag: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
  }
  
  .fear:hover {
    animation: fearAnimation 0.4s ease-in-out;
  }
  
  @keyframes fearAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .exhausted {
    transition: transform 1s linear;
  }
  