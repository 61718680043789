/* Common styles for the .EmailOverlay */
.EmailOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 999;
    min-height: 90px;
    margin-top: calc(-8vh / 2);
    margin-left: calc(-15vw / 2);
    min-width:120px;
  }
  
  .EmailText {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 0;
    text-align: center;
  }
  
  .ButtonContainer {
    /* Use flex-direction: column to stack buttons vertically */
    display: flex;
    flex-direction: row;
    justify-content: center; /* Center buttons horizontally */
    margin-top: 10px;
  }
  
  .CopyButton,
  .CloseButton {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 10px;
    

  }
  
  .CopyButton {
    background-color: #4caf50; /* Green color for Copy button */
    color: #ffffff;
    margin-right: 8px;
  }
  
  .CopyButton:hover {
    background-color: #45a049; /* Darker shade of green on hover */
  }
  
  .CloseButton {
    background-color: #f44336; /* Red color for Close button */
    color: #ffffff;
  }
  
  .CloseButton:hover {
    background-color: #c53929; /* Darker shade of red on hover */
  }
  
  /* Your other CSS styles */
  
  .EmailImage {
    cursor: crosshair; /* Set the cursor style to pointer for email images */
  }
  
  /* Mobile-specific styles: Adjust the size accordingly */
  @media screen and (max-width: 767px) {
    .EmailOverlay {
      width: 85vw;
      height: 15vh;
      margin-top: calc(-25vh / 2);
      margin-left: calc(-85vw / 2);
    }

    .ButtonContainer {
        flex-direction: column; /* Stack buttons on top of each other */
        align-items: center; /* Center buttons horizontally */
      }
    
      .CopyButton,
      .CloseButton {
        width: 100%; /* Make buttons take full width in the container */
      }
  }