html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
}
body {
  font-family: 'Poppins', sans-serif;
  background-color: #FDF2E9;
  color: #333333;
  
}
.dark-mode {
  background-color: #000000;
  color: #ffffff;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.no-scroll {
  overflow: hidden;
}

a {
  color: #B33A3A; /* Vintage red color */
  text-decoration: none; /* Remove default underline */
}

/* Styles for hyperlinks on hover */
a:hover {
  color: #CC4747; /* Slightly lighter red color on hover */
}

/* Styles for hyperlinks when clicked */
a:active {
  color: #A63030; /* Slightly darker red color when clicked */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

:root {
  --cat-enjoyer-color: #555; /* Grey color for non-hovering state */
}

.navbar-container {
  position: relative;
  z-index: 100; /* Increase the z-index of the navbar */
}

h1 {
  padding-left: 5%;
}


h4 {
  
  font-size: 1.2rem; /* Adjust the font size as needed */
  font-weight: bold; /* Apply bold style to the h4 element */
  font-family: 'Poppins', sans-serif; /* Apply the Poppins font to the h4 element */
  
}



.video-container {
  width: 100vw;
  height: 100vh;
  z-index: -100;
  margin-top: -8vh;
}

.homevideocontainer {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1500vw;
  height: 120vh;
  transform: translate(-50%, -50%);
}

.fade-out {
  transition: opacity 2s ease-out;
  opacity: 0;
}



.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Align items to the left */
  align-items: flex-start;
}

.image-column {
  flex: 0 0 auto; /* Remove the width restriction for the image column */
}
.image-column img {
  width: 100%;
  max-width: 300px; /* Adjust the max width of the image */
  height: auto;
  border-radius: 10%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.text-column {
  flex: 1; /* Let the text column take up the remaining space */
  padding-left: 40px; /* Add more spacing between the image and text */
}

.text-column p {
  font-size: 18px; /* Adjust the font size as needed */
  line-height: 1.6; /* Add some line-height for better readability */
}

.about-heading {
  font-size: 5vw; /* Set the font size relative to the viewport width */
  text-align: center; /* Center the text */
  margin: 0; /* Remove any margin to avoid spacing issues */
  padding: 0; /* Remove any padding to avoid spacing issues */
  line-height: 1.2; /* Adjust the line-height as needed for realistic spacing */
  margin-bottom:30px;
}







.projectvideocontainer {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 9 divided by 16, multiplied by 100 to get percentage */
  margin: 0 auto;
  margin-bottom:15px;
  overflow: hidden; /* Add this to hide any overflowing content */
}

.projectvideocontainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0; /* Reset margin to remove any default spacing */
  padding: 0; /* Reset padding to remove any default spacing */
}

.description {
  margin-top: 2rem;
  text-align: left;
}

.additional-images {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.additional-images img {
  width: 100%;
  max-width: 300px;
  margin: 0.5rem;
}

.project-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  padding: 2rem;
  margin: 0 auto;
  max-width: 1200px;
  padding: 2rem 2rem 2rem 80px; /* Add 80px padding to the left to create space for back-button */
}

.proj-page-title-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom:30px;
}

.back-button {
  color: #B33A3A;
  font-size: 48px;
  margin-left:-66px;
  cursor: pointer;
  position: absolute;
  left: 0; /* Align the back-button to the left edge of proj-page-title-container */
}

.proj-heading {
  font-size: 2.5vw; /* Set the font size relative to the viewport width */
  text-align: center; /* Center the text */
  margin: 0; /* Remove the default margin */
  padding: 0; /* Remove any padding to avoid spacing issues */
  
  line-height: 1.2; /* Adjust the line-height as needed for realistic spacing */
}


.work-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.work-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.project-thumbnail {
  position: relative;
  flex: 0 0 calc(100% / 3 - 0rem);
  overflow: hidden;
  margin-bottom: -0.1vh;
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s ease, transform 0.5s ease;
  z-index:1;
  height: 29vh;
  object-fit: cover;
}

.project-thumbnail:hover {
  opacity: 1;
  transform: scale(1.01);
  z-index:1444;

}

.thumbnail-image {
  object-fit: cover;
  width: 100%;
    height: 100%;
}

.thumbnail-overlay {
  position: absolute; /* Add position absolute to enable z-index */
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  color: #fff;
  transition: opacity 0.3s ease;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Set a higher z-index to bring the overlay on top of the thumbnail */
}

.project-thumbnail:hover .thumbnail-overlay {
  opacity: 1;
}

.thumbnail-title {
  margin: 0;
}

.Contact {
  position: relative;
  top: -85px;
  left: 0;
  width: 100vw;
  height: calc(100vh);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* HomePage.css or in your existing CSS file */



.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.video-frame {
  width: 80%; /* Adjust the size as needed */
  height: 90vh; /* Adjust the size as needed */
}

.close-button {
  position: absolute;
  top: 12px;
  right: 8%;
  color: white;
  font-size: 48px;
  cursor: pointer;
}

.image-viewer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70vw;
  height: 70vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  min-height: 630px;
  min-width: 750px;
  margin-top: 12vh; /* Adjust the margin-top to half of the height */
  padding:40px;
}



.image-viewer img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

/* Add any additional styles for the video overlay here */

.ImageBouncer {
  position: absolute;
}

.play-button-container {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.play-button {
  width:70px;
  cursor: pointer;
  /* Add any additional styles for the play button image here */
}


@media screen and (max-height: 600px) {
  .homevideocontainer {
    
    width: 150vw;
    height: 1200vh;
  }
}

@media screen and (max-width: 1200px) {
  .project-thumbnail {
    flex: 0 0 calc(50% - 0rem);
  }
  .homevideocontainer {
}
}

@media screen and (max-width: 768px) {
  .project-thumbnail {
    flex: 0 0 calc(100% - 0rem);
  }
  .project-page {
    padding: 1rem; /* Adjust the padding for mobile */
    max-width: 100%; /* Remove the maximum width for mobile */
  }
  .proj-heading {
    width:100%;
      font-size: 6vw; /* Adjust the font size as needed for mobile devices */
      text-align: center; /* Center the text horizontally */
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */

  
  }
  .about-heading {
    width:100%;
    font-size: 6vw;
    text-align: center; /* Center the text horizontally */
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  margin-bottom:30px;
  }
  .content-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .image-column,
  .text-column {
    flex: 0 0 100%;
  }

  .image-column {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }

  .image-column img {
    height: auto;
    width: 50%;
    max-width: 100%;
    margin: auto;
  }

  .text-column p {
    margin-left: 0;
  }

  .image-viewer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw; /* Adjust the width for mobile view */
    height: 90vh; /* Adjust the height for mobile view */
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    padding: 20px;
    padding-top:50px;
    min-height:0px;
    min-width:0px;
    margin-top:-100px;
    max-height: calc(50vh - 40px); /* Subtract the padding (20px) from the height */
    max-width: calc(100vw - 40px); /* Subtract the padding (20px) from the width */
  }

  .image-viewer img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}